import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Footer } from './styled';

const HomePage: React.FC = () => {
  const pageTitle = 'Export Business Bank - Yony Gómez';
  const pageDescription = 'A project created by Yony Eduardo Gomez Arcias that brings solutions for families, parents, and entrepreneurs, as well as for small, medium, and large companies. Learn more about Yony Gómez, one of the most influential entrepreneurs in Panama and the region.';

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content="https://www.bankexportbusiness.com/static/media/gold-logo-business-bank.6691214c31e8b1a7a071.png" />
        <meta property="og:url" content="https://exportbusinessbank.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Export Business Bank" />

      </Helmet>
      <Container>
        <p>
          A project that will bring solutions for families, parents, and entrepreneurs, as well as for small, medium, and large companies.
        </p>
        <h3>Founder</h3>
        <h2>{pageTitle}</h2>
        <h5>This new bank boasts a highly esteemed and robust board of shareholders.</h5>
        <ul>
          <li style={{ marginBottom: '10px' }}>{pageTitle}</li>
          <li>Jaqueline Goulart de Gómez Arcias</li>
        </ul>
        <h5>It will also be comprised of a board of directors, well-prepared and professional, to ensure high efficiency throughout the operational chain.</h5>

        <Footer>
          <h4>
            <a style={{ color: '#000' }} href="mailto:Yony@exportbusiness.com">Yony@exportbusiness.com</a>
          </h4>
        </Footer>
      </Container>
    </React.Fragment>
  );
}

export default HomePage;


// import React from 'react';
// import { Helmet } from 'react-helmet';
// import { Container, Footer } from './styled';

// const HomePage: React.FC = () => {
//   return (
//     <React.Fragment>
//       <Helmet>
//         <title>Export business bank</title>
//         <meta name="description" content="A project that will bring solutions for families, parents, and entrepreneurs, as well as for small, medium, and large companies. Created by Yony Eduardo Gomez Arcias " />
//       </Helmet>
//       <Container>
//         <p>
//           A project that will bring solutions for families, parents, and entrepreneurs, as well as for small, medium, and large companies.
//         </p>
//         <h3>Founder</h3>
//         <h2>Yony Eduardo Gomez Arcias, one of the most influential entrepreneurs in Panama and the region.</h2>
//         <h5>This new bank boasts a highly esteemed and robust board of shareholders.</h5>
//         <ul>
//           <li style={{ marginBottom: '10px' }}>Yony Eduardo Gomez Arcias</li>
//           <li>Jaqueline Goulart de Gómez Arcias</li>
//         </ul>
//         <h5>It will also be comprised of a board of directors, well-prepared and professional, to ensure high efficiency throughout the operational chain.</h5>

//         <Footer>
//           <h4>
//             <a style={{ color: '#000' }} href="mailto:Yony@exportbusiness.com">Yony@exportbusiness.com</a>
//           </h4>
//         </Footer>
//       </Container>
//     </React.Fragment>
//   );
// }

// export default HomePage;