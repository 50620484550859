import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import logoGold from "../assets/gold-logo-business-bank.png";
import { Image } from "./styled";

import {
  NavbarContainer,
  NavbarLinkContainer,
  NavbarLink,
  ButtonLink,
  ExtendedNavbar,
  NavbarLinkExtended,
} from "../styles/NavStyle";

const Navbar: React.FC = () => {
  // ..
  const location = useLocation();

  useEffect(() => {
    setExtendNavbar(false);
  }, [location]);
  // ..
  const [extendNavbar, setExtendNavbar] = useState(false);
  return (
    <NavbarContainer extend={extendNavbar}>
      <h1 style={{fontSize: '2rem'}} color='#fff'>Export business bank</h1>
      <Image src={logoGold} alt="logo export business bank" />
      {/* <NavbarLinkContainer>
        <NavbarLink className="nav-link active" to="/home">
          Home
        </NavbarLink>
        <NavbarLink className="nav-link" to="/about">
          About Us
        </NavbarLink>
        <NavbarLink className="nav-link" to="/contact">
          Contact Us
        </NavbarLink>
        <ButtonLink
          onClick={() => {
            setExtendNavbar((curr) => !curr);
          }}
        >
          {extendNavbar ? <>&#10005;</> : <>&#8801;</>}
        </ButtonLink>
      </NavbarLinkContainer> */}
      {extendNavbar && (
        <ExtendedNavbar>
            <h1 color='#fff'>Export business bank</h1>
          {/* <NavbarLinkExtended className="nav-link active" to="/home">
            Home
          </NavbarLinkExtended>
          <NavbarLinkExtended className="nav-link" to="/about">
            About Us
          </NavbarLinkExtended>
          <NavbarLinkExtended className="nav-link" to="/contact">
            Contact Us
          </NavbarLinkExtended> */}
        </ExtendedNavbar>
      )}
    </NavbarContainer>
  );
};
export default Navbar;
