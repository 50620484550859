import styled from "styled-components";

export const Container = styled.div`
    max-width: 100vw;
    min-height: 100vh;
    background-color: black;
    color: white;
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    padding: 20px;
`;

export const Footer = styled.footer`
    display: flex;
    flex-direction: column;
    position: fixed;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    margin-top: auto;
    padding: 10px 0px;
    text-align: center;
    font-size: 0.8rem;
    bottom: 0;
    background-color: gold;
    color: #000;
`;