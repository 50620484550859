
const ContactPage: React.FC=()=> {
    return (
        <div
        className="container text-center  bg-black"
       
      >
        <h1 className="heading">Contact us</h1>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sequi, qui.
          Hic animi distinctio et maiores, ab nostrum at neque. Iusto minus
          perspiciatis vitae unde? In quibusdam nulla perspiciatis laboriosam ex.
        </p>
      </div>
    )
}

export default ContactPage